import React, { useState, useEffect, useCallback } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { useSearchParams } from "react-router-dom";

import ArrowButton from "../ArrowButton/ArrowButton";
import Button from "../Button/Button";
import BankValues from "../BanksComponent/BankValues";
import NoBankAccount from "../BanksComponent/NoBankAccount";
import Loader from "../Loader/Loader";
import PromoCodeInput from "../PromoCodeInput/PromoCodeInput";
import AddBankAccount from "../BanksComponent/AddBankAccount";
import AddCardSubscriptionPay from "../CardsComponent/AddCardSubscriptionPay";

import { getEmailFromUrl } from "../../helpers/functionsHelpers";
import { setAlert } from "../../redux/reducers/alert/alert";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/store";
import { addPaymentMethod } from "../../redux/actions/payment";
import {
  PaymentMethodsVariantsT,
  CardT,
  BankT,
} from "../../types/paymentsTypes";
import {
  payment_method_type_card,
  payment_method_type_bank,
} from "../../helpers/constants";
import history from "../../utils/history";
import { setupIntent } from "../../redux/actions/subscriptions";
import { cleanPaymentIntentState } from "../../redux/reducers/subscriptions/subscriptions";

import styles from "./styles.module.scss";

const SubscriptionsPaymentElement: React.FC<{
  selectedSwitchValue: PaymentMethodsVariantsT | null;
  onSubmitPayment: (params: {
    paymentMethodId: string;
    promoCodeValue?: string;
  }) => void;
  setActiveCardIndex: (p: number | null) => void;
  setActiveBankIndex: (p: number | null) => void;
  activeCardIndex: number | null;
  activeBankIndex: number | null;
}> = ({
  selectedSwitchValue,
  onSubmitPayment,
  setActiveCardIndex,
  setActiveBankIndex,
  activeCardIndex,
  activeBankIndex,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const bearer = searchParams.get("bearer") || "";
  const amount = searchParams.get("amount") || "";
  const companyName = searchParams.get("companyName") || "";
  const userCurrency = searchParams.get("userCurrency") || "";
  const invoiceId = searchParams.get("invoiceId") || "";
  const invoiceCurrency = searchParams.get("invoiceCurrency") || "";
  const testMode = searchParams.get("testMode") || "";
  const name = searchParams.get("name") || "";
  const email = searchParams.get("email") || "";
  const promoCodeValue = searchParams.get("promoCodeValue") || "";

  const stripe = useStripe();
  const dispatch = useAppDispatch();

  const { paymentsAccounts } = useAppSelector((state: RootState) => state.user);
  const { paymentProcessState, removeCardState } = useAppSelector(
    (state: RootState) => state.payments
  );
  const { paymentIntentState } = useAppSelector(
    (state: RootState) => state.subscriptions
  );

  const savedCard = paymentsAccounts?.data?.card || [];
  const savedBank = paymentsAccounts?.data?.bank_account || [];

  const isShowCardSlider = savedCard.length > 0;
  const isShowBankSlider = savedBank.length > 0;

  useEffect(() => {
    if (paymentsAccounts?.data?.card?.length) {
      setActiveCardIndex(0);
    }
    if (paymentsAccounts?.data?.bank_account?.length) {
      setActiveBankIndex(0);
    }
  }, [
    paymentsAccounts?.data?.card,
    paymentsAccounts?.data?.bank_account?.length,
  ]);

  useEffect(() => {
    if (!paymentsAccounts?.data?.card?.length) {
      setActiveCardIndex(null);
    }
    if (!paymentsAccounts?.data?.bank_account?.length) {
      setActiveBankIndex(null);
    }
  }, [removeCardState]);

  useEffect(() => {
    if (paymentIntentState.data?.client_secret) {
      onGetPaymentIntent();
    }
  }, [paymentIntentState.data?.client_secret]);

  useEffect(() => {
    if (
      stripe &&
      paymentProcessState?.isRedirect &&
      paymentProcessState?.success &&
      paymentProcessState.data?.client_secret &&
      paymentProcessState.paymentMethodId &&
      paymentProcessState.data.status === "requires_action"
    ) {
      stripe
        .confirmCardPayment(paymentProcessState.data?.client_secret, {
          payment_method: paymentProcessState.paymentMethodId,
        })
        .then((result: any) => {
          if (result.error) {
            onShowError(result.error.message);
          } else {
            history.push(
              `/status?payment_intent_client_secret=${paymentProcessState.data?.client_secret}&invoiceId=${paymentProcessState.data?.invoiceId}&transactionId=${paymentProcessState.data?.transactionId}&testMode=${testMode}`
            );
          }
        });
    }
    if (
      paymentProcessState?.isRedirect &&
      paymentProcessState?.success &&
      paymentProcessState.data?.client_secret &&
      (paymentProcessState.data.status === "succeeded" ||
        paymentProcessState.data.status === "processing")
    ) {
      history.push(
        `/status?payment_intent_client_secret=${paymentProcessState.data?.client_secret}&invoiceId=${paymentProcessState.data?.invoiceId}&transactionId=${paymentProcessState.data?.transactionId}&testMode=${testMode}&status=${paymentProcessState.data.status}`
      );
    }
  }, [paymentProcessState?.success]);

  const onShowError = (error: string) => {
    dispatch(
      setAlert({
        type: "error",
        message: error,
      })
    );
  };

  const onGetPaymentIntent = () => {
    stripe
      ?.collectBankAccountForSetup({
        clientSecret: paymentIntentState?.data?.client_secret || "",
        params: {
          payment_method_type: payment_method_type_bank,
          payment_method_data: {
            billing_details: {
              name,
              email: getEmailFromUrl(window.location.search),
            },
          },
        },
      })
      //@ts-ignore
      .then((result: StripeBankAccountResponse) => {
        if (result.error) {
          onShowError(result?.error?.message || "");
        } else if (result.setupIntent) {
          if (result.setupIntent.status === "requires_confirmation") {
            stripe
              .confirmUsBankAccountSetup(result.setupIntent.client_secret)
              //@ts-ignore
              .then((res: StripeBankAccountResponse) => {
                if (res.error) {
                  onShowError(res?.error?.message || "");
                } else if (res.setupIntent) {
                  onBankAddedSuccessful(res.setupIntent.payment_method);
                }
              });
          } else if (result.setupIntent.status === "succeeded") {
            onBankAddedSuccessful(result.setupIntent.payment_method);
          }
        }
      });
  };

  const onBankAddedSuccessful = (payment_method: string) => {
    dispatch(
      addPaymentMethod({
        payment_method_id: payment_method,
        method: payment_method_type_bank,
      })
    );
    dispatch(cleanPaymentIntentState());
  };

  const onSliderClickLeftCard = () => {
    if (activeCardIndex !== 0 && activeCardIndex !== null) {
      //@ts-ignore
      setActiveCardIndex((value) => +value - 1);
    } else if (activeCardIndex === null) {
      //@ts-ignore
      setActiveCardIndex((value) => savedCard?.length - 1);
    }
  };

  const onSliderClickRightCard = () => {
    if (activeCardIndex === savedCard?.length - 1) {
      setActiveCardIndex(null);
    } else if (activeCardIndex !== null) {
      //@ts-ignore
      setActiveCardIndex((value) => value + 1);
    }
  };

  const onSliderClickLeftBank = () => {
    if (activeBankIndex !== 0 && activeBankIndex !== null) {
      //@ts-ignore
      setActiveBankIndex((value) => +value - 1);
    } else if (activeBankIndex === null) {
      //@ts-ignore
      setActiveBankIndex((value) => savedBank?.length - 1);
    }
  };

  const onSliderClickRightBank = () => {
    if (activeBankIndex === savedBank?.length - 1) {
      setActiveBankIndex(null);
    } else if (activeBankIndex !== null) {
      //@ts-ignore
      setActiveBankIndex((value) => value + 1);
    }
  };

  const onPayForSubscription = (paymentMethod: CardT | BankT) => {
    onSubmitPayment({
      paymentMethodId: paymentMethod.id,
      promoCodeValue,
    });
  };

  const onConnectBankAccount = () => {
    dispatch(setupIntent());
  };

  const onBackNavigate = useCallback(() => {
    history.back();
  }, []);

  return selectedSwitchValue === payment_method_type_card ? (
    <div className={styles.cardBody}>
      {isShowCardSlider && (
        <div className={styles.arrowLeft}>
          <ArrowButton
            variant="left"
            onClick={onSliderClickLeftCard}
            isDisabled={activeCardIndex === 0}
          />
        </div>
      )}
      {paymentsAccounts?.isInitiated ? (
        <>
          <AddCardSubscriptionPay
            cardData={
              activeCardIndex !== null ? savedCard[activeCardIndex] : undefined
            }
            onPayForSubscription={onPayForSubscription}
            onBackNavigate={onBackNavigate}
          />
        </>
      ) : (
        <Loader />
      )}
      {isShowCardSlider && (
        <div className={styles.arrowRight}>
          <ArrowButton
            variant="right"
            onClick={onSliderClickRightCard}
            isDisabled={
              activeCardIndex === savedCard?.length || activeCardIndex === null
            }
          />
        </div>
      )}
    </div>
  ) : (
    <div className={styles.bankBody}>
      {isShowBankSlider && (
        <div className={styles.arrowLeft}>
          <ArrowButton
            variant="left"
            onClick={onSliderClickLeftBank}
            isDisabled={activeBankIndex === 0}
          />
        </div>
      )}
      {paymentsAccounts?.isInitiated ? (
        activeBankIndex !== null ? (
          <>
            <BankValues bankData={savedBank[activeBankIndex]} />
            <Button
              text="Pay For The Subscription"
              onClick={() => onPayForSubscription(savedBank[activeBankIndex])}
            />
            <div className={styles.buttonWithSmallMargin}>
              <Button
                text="Back To The Subscription"
                variant="outlined"
                onClick={onBackNavigate}
              />
            </div>
          </>
        ) : (
          <div className={styles.noBankAccountWrapper}>
            {paymentsAccounts?.data?.bank_account?.length ? (
              <AddBankAccount />
            ) : (
              <NoBankAccount />
            )}
            <Button
              text="Connect Bank Account"
              onClick={onConnectBankAccount}
            />
          </div>
        )
      ) : (
        <Loader />
      )}
      {isShowBankSlider && (
        <div className={styles.arrowRight}>
          <ArrowButton
            variant="right"
            onClick={onSliderClickRightBank}
            isDisabled={
              activeBankIndex === savedBank?.length || activeBankIndex === null
            }
          />
        </div>
      )}
    </div>
  );
};

export default SubscriptionsPaymentElement;
