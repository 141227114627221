import React, { useEffect } from "react";
import { InputBase, Button } from "@mui/material";

import ClearIcon from "../../assets/images/Icon_Clear.svg";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/store";
import { setAlert } from "../../redux/reducers/alert/alert";
import { cleanPromoCodeDataState } from "../../redux/reducers/subscriptions/subscriptions";
import { getPromoCodeData } from "../../redux/actions/subscriptions";

import styles from "./styles.module.scss";

type PropsT = {
  disabled?: boolean;
  value: string;
  onValueChange: (v: string) => void;
  onSetValue: (v?: string) => void;
  isLarge?: boolean;
  placeholder?: string;
};

const PromoCodeInput: React.FC<PropsT> = ({
  value,
  onValueChange,
  onSetValue,
  isLarge,
  placeholder,
}) => {
  const dispatch = useAppDispatch();

  const { promoCodeData } = useAppSelector(
    (state: RootState) => state.subscriptions
  );

  useEffect(() => {
    if (promoCodeData?.data?.id) {
      onValueChange("");
    }
  }, [promoCodeData?.data?.id]);

  useEffect(() => {
    if (promoCodeData?.success === false) {
      onSetValue(undefined);
    }
    if (promoCodeData?.success) {
      onSetValue(value);
    }
  }, [promoCodeData?.success]);

  useEffect(() => {
    return () => {
      onClearField();
    };
  }, []);

  const onClearField = () => {
    onSetValue(undefined);
    dispatch(cleanPromoCodeDataState());
  };

  const onSetPromo = () => {
    if (value === promoCodeData?.data?.code) {
      dispatch(
        setAlert({
          type: "warning",
          message: "This promocode has been successfully accepted already",
        })
      );
      return;
    }
    dispatch(getPromoCodeData({ promoCode: value }));
  };

  return (
    <div
      className={`${
        isLarge ? styles.promoCodeContainerLarge : styles.promoCodeContainer
      }`}
    >
      {promoCodeData.data?.id ? (
        <div className={styles.promoCodeMessageContainer}>
          <div className={styles.promoCodeDescriptionWrapper}>
            <div className={styles.promoCodeBottomArrow} />
            <p className={styles.promoCodeDescriptionText}>
              {promoCodeData.data?.valid
                ? `You’ve applied promocode ${
                    promoCodeData?.data?.code || ""
                  } to get ${promoCodeData?.data?.percentOff || ""}% sale for ${
                    promoCodeData?.data?.durationInMonths || ""
                  } months.`
                : "Promocode invalid"}
            </p>
          </div>
          <div className={styles.cleanIconWrapper} onClick={onClearField}>
            <img src={ClearIcon} />
          </div>
        </div>
      ) : null}
      <div className={styles.body}>
        <InputBase
          value={value}
          placeholder={placeholder || "Enter your Promo Code"}
          inputProps={{ "aria-label": "promo code" }}
          className={styles.promoCodeInput}
          onChange={(event) => {
            onValueChange(event.target.value);
          }}
        />
        <Button
          onClick={onSetPromo}
          disabled={!value || promoCodeData?.isLoading}
          variant={"outlined"}
          className={`${styles.promoCodeButton} ${
            !value && styles.promoCodeButtonDisabled
          }`}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default PromoCodeInput;
